.list
    width: 100%
    height: auto
    margin: 15px 0
    display: grid
    grid-template-columns: repeat(6, 1fr)
    grid-gap: 20px
    grid-auto-rows: minmax(200px, auto)
    position: relative
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
    a:hover
        color: #222
        
    article
        width: 100%
        max-height: 300px
        overflow: hidden
        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, .25)
        border-radius: 2px
        transition: .3s all
        &:hover
            transform: scale(1.02)
            transition: .3s all
            box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, .45)
            color: #222
            
        header 
            height: 50px
            line-height: 20px
            display: flex
            align-items: center
            justify-content: center
            text-align: center
            padding: 10px
            font-size: 16px
            text-shadow: 0 0 1px rgba(0, 0, 0, .2)
            border-bottom: 0.5px solid rgba(0, 0, 0, .1)
            cursor: default
        .picture
            width: 100%
            height: 200px
            display: flex
            align-items: center
            justify-content: center
            img
                width: auto
                max-width: 96%
                max-height: 98%
        footer
            display: flex
            justify-content: space-between
            align-items: center
            height: 50px
            border-top: 0.5px solid rgba(0, 0, 0, .1)
            span
                font-size: 16px
                margin: 10px
                cursor: default
            .sold
                color: red
                text-shadow: 0 0 4px 1px rgba(0, 0, 0, .25)
            .inStock
                color: green
                text-shadow: 0 0 4px 1px rgba(0, 0, 0, .25)
            button
                width: 50px
                height: 50px
                background: none
                border: none
                outline: none
                font-size: 18px
                transition: .3s all
                &:hover
                    cursor: pointer
                    color: red

@media (max-width: 1360px) 
    .list
        grid-template-columns: repeat(5, 1fr)
@media (max-width: 1280px) 
    .list
        grid-template-columns: repeat(4, 1fr)
@media (max-width: 1024px) 
    .list
        grid-template-columns: repeat(3, 1fr)
@media (max-width: 568px) 
    .list
        grid-template-columns: repeat(2, 1fr)