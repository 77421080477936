.gallery
    display: flex
    position: relative

        
@media (max-width: 768px) 
    .gallery
        flex-grow: 10
        flex-wrap: wrap-reverse

    