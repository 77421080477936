@import reset-css

*
    box-sizing: border-box

html, body
    width: 100vw
    height: 100vh
    overflow: hidden auto

a 
    text-decoration: none
    color: #222
    &:hover
        color: blue

.page
    width: calc(100% - 30px)
    margin: 15px auto
    position: 'relative'