.auth
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100vh
    display: flex
    align-items: center
    justify-content: center
    background: #e9e9e9
    &_form
        width: 500px
        padding: 50px
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        border-radius: 15px
        background: #fff
        box-shadow: 8px 8px 16px 8px rgba(0, 0, 0, 0.5), -8px -8px 16px 8px rgba(255, 255, 255, 0.5)
        h3
            font-size: 36px
            text-shadow: 0 0 2px rgba(0, 0, 0, .2)
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
            margin-bottom: 25px
        .input
            width: 100%
            margin: 5px 0
            background: #F2F2F2
        .btn
            height: 60px
            margin-top: 25px
            background: #007FFF
            border: none
            padding: 0 30px
            color: #fff
            font-size: 18px
            text-shadow: 0 0 2px rgba(0, 0, 0, 0.5)
            box-shadow: 0 0 2px 1px rgba(#007FFF, 0.2)
            transition: .3s all
            border-radius: 8px
            &:hover
                background: #FF7F00
                transform: .3s




    
