.modal
    &_window
        margin: 25px
        max-width: 900px
        width: 450px
        height: auto
        background: #fff
        border-radius: 10px
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, .5)
        display: block
        overflow: hidden
        display: flex
        align-items: center
        justify-content: center
    &_blur
        display: flex
        align-items: center
        justify-content: center
        position: absolute
        top: 0
        left: 0
        background: rgba(255, 255, 255, .5)
        width: 100%
        height: 100%
        min-height: 100vh
        z-index: 1
.form
    width: 100%
    height: auto
    padding: 15px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    header
        height: 50px
        display: flex
        width: 100%
        justify-content: center
        align-items: center
        padding: 0 20px
        border-bottom: 1px solid rgba(0, 0, 0, .1)
        font-size: 22px
        text-shadow: 0 0 1px rgba(0, 0, 0, .2)
    footer
        width: 100%
        height: 50px
        display: flex
        align-items: center
        justify-content: space-between
        border-top: 1px solid rgba(0, 0, 0, .1)
        padding: 0 10px
        a
            text-shadow: 0 0 1px rgba(0, 0, 0, .2)
            font-size: 18px
            padding: 0 20px
            color: rgba(0,0,0, .5)
            cursor: pointer
            &:hover
                color: #f44336
                transform: .15s
        .btn
            height: 40px
            background: #007FFF
            border: none
            padding: 1px 30px 0px 30px
            color: #fff
            font-size: 18px
            text-shadow: 0 0 2px rgba(0, 0, 0, 0.5)
            box-shadow: 0 0 2px 1px rgba(#007FFF, 0.2)
            transition: .3s all
            border-radius: 8px
            &:hover
                background: #FF7F00
                transform: .3s
    .inputGroup
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column
        width: 100%
        padding: 10px 0 
        .input
            margin: 9px 0 2.5px 0
            width: 100%
            &:nth-child(1)
                margin-top: 0