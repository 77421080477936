.header
    width: 100%
    height: 50px
    box-shadow: 0px 0px 2px .5px rgba(0, 0, 0, 0.3)
    border-radius: 10px
    padding: 0 10px
    .active
        color: blue
    &Nav
        width: 100%
        height: 50px
        &List
            display: flex
            align-items: center
            justify-content: center
            height: 50px
            li
                height: 50px
                font-size: 18px
                padding: 0 10px
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
                text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4)
                a
                    height: 50px
                    display: flex
                    align-items: center
            li:last-child
                margin-right: 0
                margin-left: auto
                padding: 0
                button
                    height: 100%
                    border: none
                    outline: none
                    background: none
                    font-size: 16px
                    padding: 0 10px
                    &:hover
                        color: blue
    

