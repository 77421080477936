.picture
    width: 100%
    height: 500px
    display: flex
    .img
        display: flex
        justify-content: center
        align-items: center
        width: 50%
        height: 500px
        img
            width: auto
            max-width: 400px
            max-height: 400px
            border-radius: 10px
            box-shadow: 1px 1px 8px 4px rgba(0, 0, 0, .3), -1px -1px 8px 4px rgba(255, 255, 255, .3)
    .body
        width: 50%
        height: 500px
        border-left: 1px solid rgba(0, 0, 0, .1)
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
        header
            width: 100%
            height: 50px
            display: flex
            justify-content: space-between
            align-items: center
            padding: 0 20px
            border-bottom: 1px solid rgba(0, 0, 0, .1)
            font-size: 22px
            text-shadow: 0 0 1px rgba(0, 0, 0, .2)
            span
                font-size: 18px
                color: rgba(0, 0, 0, .5)
                cursor: default
        ul
            width: 100%
            height: calc(100% - 100px)
            max-height: 400px
            padding: 20px
            font-size: 20px
            li
                height: 40px
                width: 100%
                display: flex
                align-items: center
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
                .param
                    display: flex
                    align-items: center
                    height: 40px
                    width: 150px
                    color: rgba(0, 0, 0, .6)
                    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
            .inStock 
                color: green
            .sold 
                color: red
        footer
            width: 100%
            height: 50px
            display: flex
            align-items: center
            justify-content: center
            border-top: 1px solid rgba(0, 0, 0, .1)
            a
                text-shadow: 0 0 1px rgba(0, 0, 0, .2)
                font-size: 18px
                padding: 0 20px
                color: #222
                cursor: default
                &:hover
                    color: orange
            button
                text-shadow: 0 0 1px rgba(0, 0, 0, .2)
                height: 100%
                padding: 0 20px
                outline: none
                border: none
                background: none
                font-size: 18px
                color: #222
                cursor: pointer
                &:nth-child(2):hover
                        color: red
                &:nth-child(3)
                    color: rgba(0,0,0, .5)
                    margin-left: auto
                    margin-right: 0
                &:nth-child(3):hover
                        color: blue