.category
    height: fit-content
    margin: 15px 0 15px 20px
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, .25)
    width: 150px
    min-width: 150px
    text-align: center
    font-size: 20px
    text-shadow: 0 0 2px rgba(0, 0, 0, .3)
    border-radius: 5px
    padding: 10px 0
    li
        padding: 10px
        cursor: pointer
        &:hover
            background: rgba(0, 0, 0, .05)
            color: blue
    hr
        opacity: .2
    .active
        color: blue
        cursor: pointer
        background: rgba(0, 0, 0, .05)


@media (max-width: 768px) 
    .category
        display: flex
        margin: 15px 0 15px 0
        width: 100%
        flex-wrap: wrap
        box-shadow: none
        justify-content: center