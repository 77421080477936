.actionHeader 
    margin-top: 15px
    width: 100%
    height: 40px
    display: flex
    align-items: center
    justify-content: flex-start
    &_link
        display: flex
        align-items: center
        justify-content: center
        padding: 1px 30px 0px 30px
        height: 40px
        background: #007FFF
        border-radius: 8px
        color: #fff
        text-shadow: 0 0 2px rgba(0, 0, 0, 0.5)
        box-shadow: 0 0 2px 1px rgba(#007FFF, 0.2)
        transition: .3s all
        &:hover
            color: #fff
            background: #FF7F00
            transform: .3s