.modal
    &_window
        margin: 25px
        max-width: 900px
        width: 900px
        height: 500px
        background: #fff
        border-radius: 10px
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, .5)
        display: block
        overflow: hidden
        display: flex
        align-items: center
        justify-content: center
    &_blur
        display: flex
        align-items: center
        justify-content: center
        position: absolute
        top: 0
        left: 0
        background: rgba(255, 255, 255, .5)
        width: 100%
        height: 100vh
        z-index: 1



