.item
    margin: 10px 0
    padding: 0 20px
    width: 100%
    height: 75px
    border-radius: 5px
    display: flex
    align-items: center
    justify-content: space-between
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, .25)
    background: #f6f6f6
    transition: .3s all
    font-size: 20px
    &:hover
        box-shadow: 0 0 8px 1px rgba(0, 0, 0, .25)
        background: #fff
        transform: .3s
    .content
        display: flex
        flex-grow: 1
        .text
            flex-grow: 1
            text-align: center
.action
    display: flex
    .btn
        display: flex
        align-items: center
        justify-content: center
        padding: 1px 0 0 2px
        color: rgba(#222, .75)
        width: 40px
        height: 40px
        outline: none
        background: none
        border: none
        margin: 0 5px
        border-radius: 5px
        font-size: 16px
        text-shadow: 0 0 2px rgba(0, 0, 0, 0.5)
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .15)
        transition: .3s all
        &:first-child:hover
            box-shadow: 0 0 2px 1px rgba(#007FFF, 0.2)
            text-shadow: 0 0 2px rgba(255, 255, 255, 0.5)
            background: #007FFF
            color: #fff
            transform: .3s
        &:last-child:hover
            box-shadow: 0 0 2px 1px rgba(#D40000, 0.2)
            text-shadow: 0 0 2px rgba(255, 255, 255, 0.5)
            background: #D40000
            color: #fff
            transform: .3s

