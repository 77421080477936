.form
    width: 100%
    height: 500px
    display: flex
    justify-content: center
    align-items: center
    .img
        display: flex
        justify-content: center
        align-items: center
        width: 50%
        height: 500px
        img
            width: auto
            max-height: 400px
            border-radius: 10px
    .body
        width: 100
        height: 500px
        flex-grow: 1
        border-left: 1px solid rgba(0, 0, 0, .1)
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
        header
            width: 100%
            height: 50px
            display: flex
            justify-content: space-between
            align-items: center
            padding: 0 20px
            border-bottom: 1px solid rgba(0, 0, 0, .1)
            font-size: 22px
            text-shadow: 0 0 1px rgba(0, 0, 0, .2)
            span
                font-size: 18px
                color: rgba(0, 0, 0, .5)
                cursor: default
        footer
            width: 100%
            height: 50px
            display: flex
            align-items: center
            justify-content: space-between
            border-top: 1px solid rgba(0, 0, 0, .1)
            padding: 0 10px
            a
                text-shadow: 0 0 1px rgba(0, 0, 0, .2)
                font-size: 18px
                padding: 0 20px
                color: rgba(0,0,0, .5)
                cursor: pointer
                &:hover
                    color: #f44336
                    transform: .15s
            .btn
                height: 40px
                background: #007FFF
                border: none
                padding: 1px 30px 0px 30px
                color: #fff
                font-size: 18px
                text-shadow: 0 0 2px rgba(0, 0, 0, 0.5)
                box-shadow: 0 0 2px 1px rgba(#007FFF, 0.2)
                transition: .3s all
                border-radius: 8px
                &:hover
                    background: #FF7F00
                    transform: .3s
        .inputGroup
            width: 100%
            height: calc(100% - 100px)
            display: flex
            align-items: center
            justify-content: center
            flex-direction: column
            padding: 0 25px
            .input
                margin: 9px 0 2.5px 0
                width: 100%
                &:nth-child(1)
                    margin-top: 0