.container
    &:hover
        .image
            text-shadow: 0 0 2px rgba(#007FFF, 0.5)
            color: #007FFF
            transform: .3s
.buttonImage
    width: 400px
    max-width: 400px
    min-height: 400px
    overflow: hidden
    border: none
    border-radius: 15px
    margin: 10px 5px
    box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.25)
    display: flex
    align-items: center
    justify-content: center
    padding: 0
    
    
.image
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    font-size: 64px
    color: #B8B8B8
    text-shadow: 0 0 2px rgba(#B8B8B8, 0.5)
    transition: .3s all
    img 
        width: 100%
        height: auto
    
.uploadInput
    display: none
    &:focus
        outline: none
